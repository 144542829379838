import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";

import { Trans } from "@lingui/macro";

import { CopyToClipboard } from "react-copy-to-clipboard";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Stack from "@mui/material/Stack";
import LanguageIcon from "@mui/icons-material/Language";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { styled, lighten, darken } from "@mui/system";

import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import Link from "@mui/material/Link";

// export const apiURL = 'https://api.glam2.app/';
// export const apiURL = 'http://localhost:8000/';

export const initialFetch = async (url, options = {}, retryCount = 0) => {
  const { timeout = 500, maxRetries = 1000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    return await fetch(url, {
      ...options,
      signal: controller.signal,
    });
  } catch (error) {
    if (error.name === "AbortError") {
      // Handle aborted request
      if (retryCount < maxRetries) {
        let count = retryCount + 1;
        return initialFetch(
          url,
          {
            ...options,
            timeout: timeout,
          },
          count
        );
      }
      throw error;
    } else {
      // Handle other errors
      console.log(error, "WHat aistasfoiasfoi");
    }
  }
};

export const fetchRetry = async (url, options = {}, retryCount = 0) => {
  const { timeout = 10000, maxRetries = 5 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    return await fetch(url, {
      ...options,
      signal: controller.signal,
    });
  } catch (error) {
    if (error.name === "AbortError") {
      // Handle aborted request
      if (retryCount < maxRetries) {
        console.log(retryCount);
        let count = retryCount + 1;
        return fetchRetry(
          url,
          {
            ...options,
            timeout: 10000 * count,
          },
          count
        );
      }
      throw error;
    } else {
      // Handle other errors
      console.log(error, "WHat aistasfoiasfoi");
    }
  }
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const Initialize = () => {
  const state = useSelector((state) => state.core);
  const dispatch = useDispatch();

  const [productLoad, setProductLoad] = useState(false);
  const [variableLoad, setVariableLoad] = useState(false);
  // const [cropmaskLoad, setCropmaskLoad] = useState(false)

  useEffect(() => {
    dispatch({ type: "assetsLoaded", value: false });
    initialFetch(`${state.apiURL}announcements/`)
      .then((response) => response.json())
      .then((result) => {
        result.sort(function (x, y) {
          return x.sticky == true ? -1 : y.sticky == true ? 1 : 0;
        });
        dispatch({ type: "announcementList", value: result });
      });
    initialFetch(`${state.apiURL}products/?i18n=${state.language}`)
      .then((response) => response.json())
      .then((result) => {
        const products = result.results;
        let variables = [];
        let apiPromises = [];
        products.forEach((product) => {
          variables.push(product.variable);
        });
        let uniqueVariables = variables.filter(onlyUnique);
        uniqueVariables.forEach((variable) => {
          apiPromises.push(
            initialFetch(variable + `?i18n=${state.language}`).then(
              (response) => response.json()
            )
          );
        });
        Promise.all(apiPromises).then((responses) => {
          const variables = responses;
          dispatch({ type: "variableList", value: variables });
          setVariableLoad(true);
        });
        dispatch({ type: "productList", value: products });
        setProductLoad(true);
      });

    initialFetch(`${state.apiURL}cropmasks/?i18n=${state.language}&tag=global`)
      .then((response) => response.json())
      .then((result) => {
        const cropmasks = result.results;
        dispatch({ type: "cropmaskList", value: cropmasks });
        // console.log(cropmasks)
        // setCropmaskLoad(true)
      });

    // initialFetch(`${apiURL}boundary-layers/?i18n=${state.language}&limit=1000`)
    //     .then(response => response.json())
    //     .then(result => {
    //         const layers = result.results;
    //         dispatch({type: 'layerList', value: layers})
    //         // setCropmaskLoad(true)
    //     });

    initialFetch("assets/iso-country-codes.json")
      .then((response) => response.json())
      .then((result) => {
        const isoList = result.result.records;
        dispatch({ type: "isoList", value: isoList });
      });
  }, [state.language]);

  useEffect(() => {
    if (productLoad && variableLoad) {
      dispatch({ type: "primed", value: true });
      console.log("assets loaded");
    }
  }, [productLoad, variableLoad]);

  return null;
};

export const stripEmojis = (str) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const round5 = (x) => {
  return x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
};

export const getMedian = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height: "100%" }}>
          <Typography component={"span"} sx={{ height: "100%" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export const getCountryLabel = (isoList, language, layerID) => {
  if (layerID) {
    var iso3 = layerID.split("-")[1];
    var isoCode = isoList.filter((code) => code.alpha_3.toLowerCase() == iso3);
    var nameOption = `short_name_${language.split("-")[0]}`;

    if (isoCode.length > 0) {
      return isoCode[0][nameOption];
    } else return layerID;
  }
};

export const getFeatureName = (target) => {
  if (target.layer_id.includes("geoboundaries")) {
    return target.feature.properties["shapeName"];
  } else if (target.layer_id.includes("ecoregions")) {
    return target.feature.properties["ECO_NAME"];
  }
};

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

export const MaskFlags = {
  Argentina: (
    <ReactCountryFlag svg countryCode="AR" style={{ paddingRight: ".5em" }} />
  ),
  Brazil: (
    <ReactCountryFlag svg countryCode="BR" style={{ paddingRight: ".5em" }} />
  ),
  Chile: (
    <ReactCountryFlag svg countryCode="CL" style={{ paddingRight: ".5em" }} />
  ),
  Mali: (
    <ReactCountryFlag svg countryCode="ML" style={{ paddingRight: ".5em" }} />
  ),
  Togo: (
    <ReactCountryFlag svg countryCode="TG" style={{ paddingRight: ".5em" }} />
  ),
  Global: <LanguageIcon sx={{ pr: 1 }} />,
  None: <DoNotDisturbAltIcon sx={{ pr: 1 }} />,
};

export const SocialStack = ({ title, url }) => {
  const coreState = useSelector((state) => state.core);
  const [copied, setCopied] = useState(false);

  return (
    <Stack direction="row" spacing={2}>
      <CopyToClipboard
        text={url}
        onCopy={() => {
          setCopied(true);
        }}
      >
        <Tooltip title={copied ? <Trans>Copied!</Trans> : <Trans>Copy</Trans>}>
          <span>
            <Avatar
              sx={{
                width: 48,
                height: 48,
                cursor: "pointer",
                bgcolor: copied ? "#4caf50" : "#bdbdbd",
              }}
            >
              <ContentCopyIcon />
            </Avatar>
          </span>
        </Tooltip>
      </CopyToClipboard>

      <Tooltip title={<Trans>Email</Trans>}>
        <span>
          <EmailShareButton url={url} subject={title}>
            <EmailIcon size={48} round />
          </EmailShareButton>
        </span>
      </Tooltip>

      <Tooltip title="Twitter">
        <span>
          <TwitterShareButton title={title} url={url}>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
        </span>
      </Tooltip>

      <Tooltip title="Facebook">
        <span>
          <FacebookShareButton title={title} url={url}>
            <FacebookIcon size={48} round />
          </FacebookShareButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export const InitialLoading = () => {
  const coreState = useSelector((state) => state.core);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100%"
      sx={{
        bgcolor:
          coreState.theme.palette.mode == "light"
            ? "inheret"
            : coreState.theme.palette.grey[800],
      }}
    >
      <CircularProgress color="gold" size={100} />
    </Box>
  );
};

// https://lowmess.com/blog/fetch-with-timeout
// export const fetchWithTimeout = (uri, options = {}, time = 5000) => {
//   // Lets set up our `AbortController`, and create a request options object
//   // that includes the controller's `signal` to pass to `fetch`.
//   const controller = new AbortController()
//   const config = { ...options, signal: controller.signal }
//   // Set a timeout limit for the request using `setTimeout`. If the body
//   // of this timeout is reached before the request is completed, it will
//   // be cancelled.
//   const timeout = setTimeout(() => {
//     controller.abort()
//   }, time)
//   return fetchRetry(uri, config)
//     .then((response) => {
//       // Because _any_ response is considered a success to `fetch`, we
//       // need to manually check that the response is in the 200 range.
//       // This is typically how I handle that.
//       if (!response.ok) {
//         throw new Error(`${response.status}: ${response.statusText}`)
//       }
//       return response
//     })
//     .catch((error) => {
//       // When we abort our `fetch`, the controller conveniently throws
//       // a named error, allowing us to handle them separately from
//       // other errors.
//       if (error.name === 'AbortError') {
//         throw new Error('Response timed out')
//       }
//       console.error(error.message)
//     })
// }
